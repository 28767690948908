import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Marquee from 'react-fast-marquee';
import FancyTitle from '../components/fancy-title';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import * as icons from '../icons/icon-pack';
import ImagePane from '../components/image-pane';

function ProductsDetailTemplate({ data }) {
  library.add({ ...icons });

  const product = data.productsYaml;
  const image = getImage(product.image);

  const gallery = product.slider || [];
  const mobileGallery = product.mobileSlider || [];

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: product.title, fullWidth: true });
        return (
          <>
            <Seo title={product.metaTitle} description={product?.metaDescription.replace(/(<([^>]+)>)/ig, '')} />
            <div className="px-4 lg:px-16 pt-20 lg:pt-36 product-detail">
              <ImagePane
                image={(
                  <div>
                    { image
                      && (
                      <GatsbyImage
                        image={image}
                        alt={product.title}
                        className="w-full"
                      />
                      )}
                  </div>
                )}
                title={(
                  <FancyTitle
                    text={product.title}
                    marginBottom="mb-10"
                    tag="h1"
                  />
                )}
                desktopImageWidth="xl:w-1/2"
                desktopTitleWidth="xl:w-1/2"
                desktopTitleVerticalPosition="center"
                desktopImagePosition="right"
                content={(
                  <>
                    <p className="leading-6 mt-8" dangerouslySetInnerHTML={{ __html: product.description }} />
                    {
                      product.ctaUrl && (
                        <Link
                          to={product.ctaUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-block button-primary mt-4"
                        >
                          SHOPIFY APP HINZUFÜGEN
                        </Link>
                      )
                    }
                    {
                      product.excerpt && (
                        <p className="leading-6 mt-8 lg:mt-16 [&>a]:inline-block" dangerouslySetInnerHTML={{ __html: product.excerpt }} />
                      )
                    }
                  </>
                )}
              />
              { product.subtitle || product.subdescription ? (
                <div className="mt-[100px] lg:mt-60">
                  <FancyTitle text={product.subtitle} tag="h2" />
                  <p className="leading-6" dangerouslySetInnerHTML={{ __html: product.subdescription }} />
                </div>
              ) : ''}
              {
                product?.usps && (
                  <div className="mt-[100px] lg:mt-60 grid grid-cols-1 lg:grid-cols-3 gap-24">
                    { product.usps?.map((usp) => (
                      <div className="py-6" key={`t-${usp.title}`}>
                        <div className="flex items-center">
                          <div className="min-w-[60px] rounded-full w-[60px] h-[60px] inline-flex justify-center items-center bg-latori-green-dark mr-3">
                            <FontAwesomeIcon
                              icon={icon({ prefix: 'far', iconName: usp.icon })}
                              size="lg"
                              className="text-white"
                            />
                          </div>
                          <FancyTitle
                            text={usp.title}
                            marginBottom=""
                            fontSize="text-lg"
                            className="fancy-title-small"
                            tag="h2"
                          />
                        </div>
                        <p className="leading-6 pt-6" dangerouslySetInnerHTML={{ __html: usp.description }} />
                      </div>
                    ))}
                  </div>
                )
              }

              <div className="mt-[100px] lg:mt-60">
                <FancyTitle text={product.benefitstitle} tag="h2" />
                <div className="py-12 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-6 items-start">
                  { product.benefits.map((benefit) => (
                    <div key={`benefit-${benefit.title}`} className="flex flex-col justify-center items-center gap-6">
                      <div className="w-[72px] h-[72px] rounded-full bg-latori-green-dark flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={icon({ prefix: 'far', iconName: benefit.icon })}
                          size="xl"
                          className="text-white"
                        />
                      </div>
                      <p className="m-0 text-center">{benefit.title}</p>
                    </div>
                  )) }
                </div>
              </div>
            </div>
            <div className="pt-8 pb-[100px] hidden md:block">
              <Marquee speed={80} pauseOnClick>
                {[...gallery, ...gallery].map((e, i) => {
                  const _image = getImage(e.image);
                  return (
                    <div
                      className={
                      clsx('inline-block', i > 0 ? 'ml-4' : '')
                    }
                      style={{ height: '450px' }}
                    // eslint-disable-next-line react/no-array-index-key
                      key={`gallery-loop-${i}`}
                    >
                      {_image && (
                      <GatsbyImage
                        image={_image}
                        imgStyle="bg-green-600 max-h-[500px] md:object-none object-contain"
                        className="h-full"
                        alt="gallery image"
                      />
                      )}
                    </div>
                  );
                })}
              </Marquee>
            </div>
            <div className="pt-8 pb-[100px] block md:hidden">
              <Marquee speed={80} pauseOnClick>
                {[...mobileGallery, ...mobileGallery].map((e, i) => {
                  const _image = getImage(e.image);
                  return (
                    <div
                      className={
                      clsx('inline-block', i > 0 ? 'ml-4' : '')
                    }
                      style={{ height: '200px' }}
                    // eslint-disable-next-line react/no-array-index-key
                      key={`gallery-loop-${i}`}
                    >
                      {_image && (
                      <GatsbyImage
                        image={_image}
                        imgStyle="bg-green-600 max-h-[200px] md:object-none object-contain"
                        className="h-full"
                        alt="gallery image"
                      />
                      )}
                    </div>
                  );
                })}
              </Marquee>
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default ProductsDetailTemplate;

export const query = graphql`
  query ProductDetailQuery($id: String!) {
    productsYaml ( id: { eq: $id } ) {
      title
      metaTitle
      metaDescription
      slug
      description
      excerpt
      image {
        childImageSharp {
          gatsbyImageData(
            width: 720
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      link
      subtitle
      subdescription
      usps {
        title
        description
        icon
      }
      benefitstitle
      ctaUrl
      benefits {
        icon
        title
      }
      slider{
        image {
          childImageSharp {
            gatsbyImageData(
              height: 450
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      mobileSlider:slider{
        image {
          childImageSharp {
            gatsbyImageData(
              height: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
